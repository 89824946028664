import Vue from 'vue';
import Vuex from 'vuex';
import siteSettings from './modules/siteSettings';
import me from './modules/me';
import loading from './modules/loading';
import alert from './modules/alert';
import modal from './modules/modal';
import sidebar from './modules/sidebar';
import codeTable from './modules/codeTable';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        siteSettings,
        me,
        loading,
        alert,
        modal,
        sidebar,
        codeTable
    },
    strict: debug
})