/*
* This is a custom http client created from axios
*/
import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'
import store from '@/store'
import router from '@/router'

let apiRoot = '';
let serverRoot = '';

let cfPort = 8505;
axios.defaults.withCredentials = true;
axios.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
axios.defaults.headers.get['Cache-Control'] = 'no-cache';
axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Content-Type'] = 'application/json;';

if(process.env.NODE_ENV !== 'production') {
    serverRoot = 'http://localhost:' + cfPort;
    apiRoot = serverRoot + '/';
}

export const API_ROOT = `${apiRoot}`;
export const SERVER_ROOT = `${serverRoot}`;

const httpInstance = axios.create({
    baseURL: apiRoot
});
httpInstance.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

httpInstance.CancelToken = axios.CancelToken;
httpInstance.isCancel = axios.isCancel;

httpInstance.getResponseData = function(response) {
    if(response.status !== 200) throw "Invalid response";
    return response.data.data
}

httpInstance.interceptors.request.use(config => {
    // Do something before the request is sent
    store.dispatch('loading/loadingOn');
    return config;
}, function() {
    // Do something with request error
    store.dispatch('loading/loadingReset');
    store.dispatch('alert/failure', 'Check your internet connection and try again!');
});

// Add a response interceptor
httpInstance.interceptors.response.use(response => {
    // Do something with response data
    store.dispatch('loading/loadingOff');
    handle(response);
    return response;
}, function(error) {
    if(axios.isCancel(error)) {
        store.dispatch('loading/loadingOff');
    }
    else {
        // Do something with response error
        store.dispatch('loading/loadingReset');
        if(error.response && error.response.status) {
            handle(error.response);
        }
        else
            store.dispatch('alert/failure');
    }
});

function handle(response) {
    if(response.status == 200 && response.config.method != 'GET') {
        if(response.data.messages && response.data.messages.length > 0) {
            for(var message1 of response.data.messages) {
                store.dispatch('alert/success', message1);
            }
        }
    }

    if(response.status == 250 || response.status == 500 || response.data.error == true) {
        displayErrorAlerts(response.data.messages);
    }

    //For requests that expect blobs
    if(response.request.responseType == "blob" && response.data.type == 'application/json'){
        response.data.text().then(text =>{
            var dataStruct = JSON.parse(text);
            if(dataStruct.error == true){
                displayErrorAlerts(dataStruct.messages);
            }
        });
    }

    if(response.status == 251 || response.status == 501) {
        store.dispatch('alert/failure', 'Can\'t view this page');
    }

    if(response.status == 231 || response.status == 401) {        
        store.dispatch('me/getMe');
        router.push('/login');
    }
} 

function displayErrorAlerts(messages){
    if(!messages || messages.length < 1) {
        store.dispatch('alert/failure', 'Oops! Looks like something went wrong on our end, our programmers have been notified of this error and will fix it as soon as possible!');
    }
    if(messages) {
        for(var message2 of messages) {
            store.dispatch('alert/failure', message2);
        }
    }
}

loadProgressBar({}, httpInstance);

export default httpInstance;