import siteSettings from '@/api/boilerplate/siteSettings.js';

const state = {
    SiteName:'',
    SiteDescription:'',
    ContactPhone:'',
    ContactEmail:'',
    DevEmail:'',
    CurrentYear:'',
    SkywardDown:0,
    CSDLoginURL:'',
    initialized: false,

    AcceptingCourseRequests:'',
    MaxCoursesEnrolledHigh:'',
    MaxCoursesEnrolledMiddle:''
}

const getCurrentSchoolYear = (relativeDate) => {
    if(typeof this != 'undefined' && this.$state.currentYear != '') return this.$state.currentYear;
    var today = relativeDate || new Date();
    if(today.getMonth() < 6){
        //Jan-June
        return today.getFullYear();
    }
    else{
         //Jul-Dec
        return today.getFullYear() + 1;
    }
}

const getToday = () => {
    var dateObject = new Date();
    var toReturn = dateObject.getMonth()+1 <= 9 ? "0" + (dateObject.getMonth()+1) : dateObject.getMonth()+1;
    toReturn += "/";
    toReturn += dateObject.getDate() <= 9 ? "0" + dateObject.getDate() : dateObject.getDate();
    toReturn += "/";
    toReturn += dateObject.getFullYear();
    return toReturn;
}

const getEstimatedTerm = (relativeDate) => {
    if(typeof this != 'undefined' && this.$state.estimatedTerm != '') return this.$state.estimatedTerm;
    var today = relativeDate || new Date();
    if(today.getMonth() < 1 && today.getDate() < 17){
        //Jan 17th or earlier
        return 2;
    } 
    else if(today.getMonth() < 3){
        //Mar or earlier
        return 3;
    }
    else if(today.getMonth() < 6){
        //June or earlier
        return 4;
    }
    else if(today.getMonth() < 10){
        //Oct or earlier
        return 1;
    }
    else{
        //Nov and Dec
        return 2;
    }
}

const getEstimatedTermBrighton = (relativeDate) => {
    if(typeof this != 'undefined' && this.$state.EstimatedTermBrighton != '') return this.$state.EstimatedTermBrighton;
    var today = relativeDate || new Date();
    if(today.getMonth() < 2){
        //Feb or earlier
        return 2;
    } 
    else if(today.getMonth() < 6){
        //June or earlier
        return 3;
    }
    else if(today.getMonth() < 11){
        //Nov or earlier
        return 1;
    }
    else{
        //Dec
        return 2;
    }
}

const getters = {
    getCurrentSchoolYear: () => {return getCurrentSchoolYear},
    getToday: () => {return getToday},
    getEstimatedTerm: () => {return getEstimatedTerm},
    getEstimatedTermBrighton: () => {return getEstimatedTermBrighton}
}

const actions = {
    get({ commit }) {
        return siteSettings.get().then(response => {
            commit('setSiteSettings', response);
        })
    }
}

const mutations = {
    setSiteSettings(state, payload) {
        state.SiteName = payload.SiteName;
        state.SiteDescription = payload.SiteDescription;
        state.ContactPhone = payload.ContactPhone;
        state.ContactEmail = payload.ContactEmail;
        state.DevEmail = payload.DevEmail;
        state.CurrentYear = payload.CurrentYear;
        state.SkywardDown = payload.SkywardDown;
        state.CSDLoginURL = payload.CSDLoginURL;
        state.initialized = true;

        state.AcceptingCourseRequests = payload.AcceptingCourseRequests;
        state.MaxCoursesEnrolledHigh = payload.MaxCoursesEnrolledHigh;
        state.MaxCoursesEnrolledMiddle = payload.MaxCoursesEnrolledMiddle;
        
        // Set the title with the new site settings
        var newTitle = payload.SiteName;
        if(document.title.indexOf('|') >= 0) {
            var subtitle = document.title.split(' | ').pop();
            if(subtitle.indexOf('|') >= 0)
                newTitle = newTitle + ' ' + subtitle;
            else
                newTitle = newTitle + ' | ' + subtitle;
        }
        document.title = newTitle;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}