import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import wysiwyg from "vue-wysiwyg";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

// import sass
import '@/assets/sass/main.scss';

Vue.config.productionTip = false;

Vue.use(wysiwyg, {});

library.add(fad);

const app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

if (window.Cypress) {
    window.app = app
}