<template>
<div id="app">
    <ul id="skip-links">
        <li><a href="#main-content">Skip to Content</a></li>
        <li><a href="#Sidebar-Inner">Skip to Site Nav</a></li>
    </ul>
    <Alert />
    <Header />
    <div id="Page-Wrapper">
        <Sidebar />
        <div id="main-content" class="Content-Wrapper">
            <router-view></router-view>
        </div>
    </div>
    <footer>

    </footer>
    <!-- <Footer /> -->
    <Modal />
</div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/header/Header.vue";
import Sidebar from "@/components/sidebar/Sidebar.vue";

// import Footer from "@/components/Footer.vue";
import Modal from "@/components/modal/Modal.vue";
import Alert from "@/components/alert/Alert.vue";

import router from "@/router";

export default {
    name: 'app',
    components: {
        Header,
        Sidebar,
        // Footer,
        Modal,
        Alert
    },
    created(){
        this.$store.dispatch('siteSettings/get');
        
        //If getting 'me' wasn't performed by the router (which it does when 'requiresAuth' routes are landed on first), do it here
        router.onReady(() => {
            if(!router.currentRoute.matched.some(record => record.meta.requiresAuth)) {
                this.$store.dispatch('me/getMe');
            }
        })
    },
    watch: {
        '$route' () {
            this.$store.dispatch('modal/modalReset');
        }
    }
}
</script>
