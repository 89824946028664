

const state = {
    toggled:false,
}

const getters = {

}

const actions = {
    toggleSidebar({ state, commit }, status) {
        if (typeof status == "boolean") {
            commit('setToggled', status);
        } else {
            commit('setToggled', !state.toggled)
        }
    }
}

const mutations = {
    setToggled(state, status) {
        state.toggled = status;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}