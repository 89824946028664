import http from '@/api/http';

const handlerUrl = 'boilerplate/siteUser/';

export default {
    getMyTiles(){
        return http.get(handlerUrl+'getMyTiles').then(response => http.getResponseData(response));
    },
    setStoredID(NewID, NewIDType){
        return http.put(handlerUrl+'setStoredID?NewID='+NewID+'&NewIDType='+NewIDType).then(response => http.getResponseData(response));
    },
    
    list(filter){
        return http.get(handlerUrl+'list?filter='+encodeURIComponent(JSON.stringify(filter))).then(response => http.getResponseData(response));
    },
    getMe(){
        return http.get(handlerUrl+'me').then(response => http.getResponseData(response));
    },
    get(ID){
        return http.get(handlerUrl+'show?ID='+ID).then(response => http.getResponseData(response));
    },

    createFromCSDNameID(CSDNameID){
        return http.post(handlerUrl+'createFromCSDNameID',{CSDNameID:CSDNameID}).then(response => http.getResponseData(response));
    },

    togglePermissionGroup(ID, PermGroupID){
        return http.post(handlerUrl+'togglePermissionGroup',{ID:ID,PermGroupID:PermGroupID}).then(response => http.getResponseData(response));
    },
    update(SiteUser){
        return http.put(handlerUrl+'update',{SiteUser:SiteUser}).then((response) => http.getResponseData(response));
    },

    login(credentials){
        var authdata = btoa(credentials.username + ':' + credentials.password);
        return http.post(handlerUrl+'login', {}, {headers: {"Authorization": "Basic " + authdata}}).then(function(response){
            return response.data.data;
        });
    },
    loginViaSkywardCredentials(credentials){
        var authdata = btoa(credentials.username + ':' + credentials.password);
        return http.post(handlerUrl + 'loginViaSkywardCredentials', {}, {headers: {"Authorization": "Basic " + authdata}}).then(function(response){
            return response.data.data;
        });
    },
    loginViaAccessToken(AccessToken) {
        var authdata = btoa(AccessToken);
        return http.post(handlerUrl+'loginViaAccessToken', {}, {headers: {"Authorization": "Basic " + authdata}}).then(response => http.getResponseData(response));
    },
    logout(){
        return http.post(handlerUrl+'logout');
    },
    impersonate(ID){
        return http.post(handlerUrl+'impersonate',{ID:ID}).then(response => http.getResponseData(response));
    },
    stopImpersonating(){
        return http.post(handlerUrl+'stopImpersonating').then(response => http.getResponseData(response));
    }
}