<template>
<div id="Sidebar" :class="{toggled: sidebar.toggled}">
    <div id="Sidebar-Inner">
        <nav class="Site-Nav">
            <ul>
                <NavItem
                    v-for="item in menuItems"
                    :key="item.label"
                    :path="item.path"
                    :label="item.label"
                    :tag="item.tag"
                    :icon="item.icon"
                    :children="item.children"
                    :windowWidth="windowWidth" />
            </ul>
        </nav>
    </div>
</div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import NavItem from "@/components/sidebar/NavItem.vue";
import { faBars, faCog, faHistory, faHouse, faLaptopCode, faInfo, faTasks, faUsers, faTicket,
faComments, faUserPlus, faChalkboardTeacher, faSeedling, faTreeAlt, faWrench, faTicketAlt } from "@fortawesome/pro-solid-svg-icons";
import StickySidebar from "sticky-sidebar-v2";
import ResizeSensor from "resize-sensor";

export default {
    name:"Sidebar",
    components:{
        NavItem
    },
    computed:{
        ...mapState({
            siteSettings: (state) => state.siteSettings,
            me: (state) => state.me,
            sidebar: (state) => state.sidebar,
        }),
        menuItems(){
            let items = [
                //Student
                {
                    label: "Home",
                    path: "/AccountHome",
                    icon:faHouse,
                    permission: this.me.ID && this.me.IsStudent != '0' && this.me.IsGuardian != '1'
                },
                //End Student
                /*Teacher*/
                {
                    label: "Account Courses Dashboard",
                    path: "/AccountManagement/AccountCoursesDashboard",
                    icon:faChalkboardTeacher,
                    permission: !this.me.Permissions.hascan_view_accounts && this.me.Permissions.hascan_view_accounts_assigned_to_H
                },
                {
                    label: "Account Dashboard",
                    path: "/AccountManagement/AccountDashboard",
                    icon:faTreeAlt,
                    permission: !this.me.Permissions.hascan_view_accounts && this.me.Permissions.hascan_view_accounts_assigned_to_H
                },
                {
                    label: "Elementary School Students",
                    path: "/EMAccountDashboard",
                    icon:faSeedling,
                    permission: !this.me.Permissions.hascan_view_accounts && this.me.Permissions.hascan_view_accounts_assigned_to_EM
                },
                {
                    label: "My Communications",
                    path: "/AccountManagement/Communications",
                    icon:faComments,
                    permission: !this.me.Permissions.hascan_view_account_communications && 
                        (this.me.Permissions.hascan_view_assigned_accounts_communications_EM || this.me.Permissions.hascan_view_assigned_accounts_communications_H)
                },
                {
                    label: "Attendance",
                    path: "/AccountManagement/Attendance",
                    icon:faComments,
                    permission: !this.me.Permissions.hascan_view_account_communications && this.me.Permissions.hascan_view_assigned_accounts_communications_H
                },
                {
                    label: "Student Tickets",
                    path: "/Tickets",
                    icon:faTicketAlt,
                    permission: !(this.me.Permissions.hascan_view_tickets || this.me.Permissions.hascan_view_tickets_at_my_assigned_school) && this.me.Permissions.hascan_view_edit_tickets_assigned_to_me
                },
                {
                    label: "My Tickets",
                    path: "/MyTickets",
                    icon:faTicket,
                    permission: parseInt(this.me.TeacherID) > 0
                },
                /**/
                //Admin
                {
                    label:"Account Management",
                    path:"/AccountManagement",
                    icon:faUsers,
                    permission:this.me.Permissions.hascan_view_accounts,
                    children: [
                        {
                            label: "Account Courses Dashboard",
                            path: "/AccountManagement/AccountCoursesDashboard",
                            icon:faChalkboardTeacher,
                            permission: this.me.Permissions.hascan_view_accounts || this.me.Permissions.hascan_view_accounts_assigned_to_H
                        },
                        {
                            label: "Account Dashboard",
                            path: "/AccountManagement/AccountDashboard",
                            icon:faTreeAlt,
                            permission: this.me.Permissions.hascan_view_accounts || this.me.Permissions.hascan_view_accounts_assigned_to_H
                        },
                        {
                            label: "Account Dashboard (Elementary)",
                            path: "/AccountManagement/EMAccountDashboard",
                            icon:faSeedling,
                            permission: this.me.Permissions.hascan_view_accounts
                        },
                        {
                            label: "Communications",
                            path: "/AccountManagement/Communications",
                            icon:faComments,
                            permission: this.me.Permissions.hascan_view_account_communications
                        },
                        {
                            label: "Attendance",
                            path: "/AccountManagement/Attendance",
                            icon:faComments,
                            permission: this.me.Permissions.hascan_view_account_communications
                        },
                        {
                            label: "Audits",
                            path: "/AccountManagement/Audits",
                            icon:faTasks,
                            permission: this.me.Permissions.hascan_run_account_audits
                        },
                        {
                            label: "Create New Account",
                            path: "/AccountManagement/CreateAccount",
                            icon:faUserPlus,
                            permission: this.me.Permissions.hascan_edit_accounts
                        }
                    ]
                },
                {
                    label:"Course Management",
                    path:"/CourseManagement",
                    icon:faWrench,
                    permission:this.me.Permissions.hascan_view_course_management,
                    children: [
                        {
                            label: "Curriculum Management",
                            path: "/CourseManagement/CurriculumManagement",
                            icon:faWrench,
                            permission:this.me.Permissions.hascan_view_course_management
                        },
                        {
                            label: "Course Management",
                            path: "/CourseManagement/CourseManagement",
                            icon:faWrench,
                            permission:this.me.Permissions.hascan_view_course_management
                        }
                    ]
                },
                {
                    label: "Tickets",
                    path: "/Tickets",
                    icon:faTicketAlt,
                    permission: this.me.Permissions.hascan_view_tickets || this.me.Permissions.hascan_view_tickets_at_my_assigned_school
                },
                {
                    label:"Settings",
                    path:"/Settings",
                    icon:faCog,
                    permission:this.canAccessSettingsTab,
                    children: [
                        
                        {
                            label: "Communication Templates",
                            path: "/Settings/CommunicationTemplates",
                            permission: this.me.Permissions.hascan_edit_communication_templates,
                        },
                        {
                            label: "Course Families",
                            path: "/Settings/CourseFamilys",
                            permission: this.me.Permissions.hascan_edit_courses,
                        },
                        {
                            label: "Manual Email Templates",
                            path: "/Settings/ManualEmailTemplates",
                            permission: this.me.Permissions.hascan_edit_manual_email_templates,
                        },
                        {
                            label: "Permission Groups",
                            path: "/Settings/PermGroups",
                            permission: this.me.Permissions.hascan_view_groups,
                        },
                        {
                            label: "Schools",
                            path: "/Settings/Schools",
                            permission: this.me.Permissions.hascan_edit_schools,
                        },
                        {
                            label: "School Sessions",
                            path: "/Settings/SchoolSessions",
                            permission: this.me.Permissions.hascan_edit_school_sessions,
                        },
                        {
                            label: "Site Settings",
                            path: "/Settings/SiteSettings",
                            permission: this.me.Permissions.hascan_edit_site_settings,
                        },
                        {
                            label: "Teachers",
                            path: "/Settings/Teachers",
                            permission: this.me.Permissions.hascan_edit_teachers,
                        },
                        {
                            label: "Ticket Topics",
                            path: "/Settings/TicketTopics",
                            permission: this.me.Permissions.hascan_edit_ticket_topics,
                        },
                        {
                            label: "USBE Reimbursements",
                            path: "/Settings/USBEReimbursements",
                            permission: this.me.Permissions.hascan_edit_USBE_reimbursements,
                        },
                        {
                            label: "Users",
                            path: "/Settings/Users",
                            permission: this.me.Permissions.hascan_view_users,
                            // Adding children to child items is technically possible, but discouraged.
                            children: [
                                {
                                    label: "Employees",
                                    path: "/Settings/Users/Employees",
                                },
                                {
                                    label: "Students",
                                    path: "/Settings/Users/Students",
                                }
                            ]
                        },
                        {
                            label: "Verbiage",
                            path: "/Settings/Verbiage",
                            permission: this.me.Permissions.hascan_update_verbiage,
                        }
                    ]
                },
                {
                    label: "Site Activity",
                    path: "/SiteActivity",
                    icon:faHistory,
                    permission: this.me.Permissions.hascan_view_site_activity
                },
                {
                    label: "Documentation",
                    path: "/Documentation",
                    icon:faInfo,
                    permission: this.me.ID && !this.me.IsStudent && !this.me.IsGuardian
                },
                {
                    label: "Development Team Contact",
                    path: "https://itdevelopmentrequests.canyonsdistrict.org/?#/ProjectSplash?ProjectID=36",
                    icon:faLaptopCode,
                    permission: this.me.Permissions.hascan_view_it_dev_req_link
                }
            ];

            return items.filter((item) => {
                return this.filterItem(item);
            });
        },
        canAccessSettingsTab() {
            return (
                !this.me.IsStudent &&
                !this.me.IsGuardian &&
                (this.me.Permissions.hascan_edit_site_settings ||
                    //this.me.Permissions.hascan_view_users ||
                    this.me.Permissions.hascan_view_groups ||
                    this.me.Permissions.hascan_update_verbiage)
            );
        }
    },
    data(){
        return {
            faBars,
            faCog,
            faHistory,
            sidebarIcon:faBars,
            windowWidth:window.innerWidth,
            stickySidebar:null
        };
    },
    mounted(){
        window.addEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });

        let sidebarElement = document.getElementById("Sidebar");
        let sidebarInnerElement = document.getElementById("Sidebar-Inner");

        sidebarElement.addEventListener("affixed.static.stickySidebar", () => {
            // prevents the sidebar from jumping down on initial scroll when innerSidebar is not taller than viewport
            if (sidebarInnerElement.getBoundingClientRect().bottom < window.innerHeight) {
                sidebarInnerElement.style.position = "fixed";
            }
        });

        new ResizeSensor(sidebarElement, () => {
            // sticky-sidebar library doesn't like when the Sidebar container changes size (such as when toggled)
            // overwrites the inline width setting that the sticky-sidebar library sets
            sidebarInnerElement.style.width = sidebarElement.style.width;
        });

        // initialize the sticky sidebar
        this.stickySidebar = new StickySidebar("#Sidebar", {
            containerSelector: "#Page-Wrapper",
            innerWrapperSelector: "#Sidebar-Inner",
            bottomSpacing: 300, // leaves space for sub-menus on bottom items
            minWidth: 769 // sticky sidebar is turned off on the mobile menu
        });
    },
    methods: {
        ...mapActions({
            getMe: "me/getMe",
            getSiteSettings: "siteSettings/get",
            refreshPendingReports: "sidebar/refreshPendingReports",
        }),
        filterItem(item) {
            // remove children where permission = false
            if ("children" in item) {
                item.children = item.children.filter((child) => {
                    return this.filterItem(child);
                });
            }

            // remove parents where permission = false
            if ("permission" in item) {
                return item.permission;
            }
            return true;
        }
    }
};
</script>