import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

Vue.use(Router)

let router = new Router({
    routes: [
        /*Navbar Tabs*/
        {
            path:'/AccountManagement',
            component:() => import('@/views/AccountManagement/AccountManagement'),
            meta:{
                requiresAuth:true,
            },
            children: [
                {
                    name:'AccountDashboard',
                    path: 'AccountDashboard',
                    component: () => import('@/views/AccountManagement/Routes/AccountDashboard'),
                    meta: {
                        title: 'Account Dashboard (High)'
                    }
                },
                {
                    name: 'AccountCoursesDashboard',
                    path: 'AccountCoursesDashboard',
                    component: () => import('@/views/AccountManagement/Routes/AccountCoursesDashboard'),
                    meta: {
                        title: 'Account Courses Dashboard (High)'
                    }
                },
                {
                    name: 'EMAccountDashboard',
                    path: 'EMAccountDashboard',
                    component: () => import('@/views/AccountManagement/Routes/EMAccountDashboard'),
                    meta: {
                        title: 'Account Dashboard (Middle/Elementary)'
                    }
                },
                {
                    name: 'Communications',
                    path: 'Communications',
                    component: () => import('@/views/AccountManagement/Routes/Communications'),
                    meta: {
                        title: 'Communications'
                    }
                },
                {
                    name: 'Attendance',
                    path: 'Attendance',
                    component: () => import('@/views/AccountManagement/Routes/Attendance'),
                    meta: {
                        title: 'Attendance'
                    }
                },
                {
                    name: 'Audits',
                    path: 'Audits',
                    component: () => import('@/views/AccountManagement/Routes/Audits/Audits'),
                    meta: {
                        title: 'Audits'
                    }
                },
                {
                    name: 'CreateAccount',
                    path: 'CreateAccount',
                    component: () => import('@/views/AccountManagement/Routes/CreateAccount'),
                    meta: {
                        title: 'Create Account'
                    }
                },
                { 
                    //This is a fallback for if a route is not found above inside 'AccountManagement'
                    path:"", 
                    redirect: 'LoggedInRedirect' 
                }
            ]
        },
        {
            path:'/CourseManagement',
            component:() => import('@/views/CourseManagement/CourseManagementRouteParent'),
            meta:{
                requiresAuth:true,
            },
            children: [
                {
                    name:'CurriculumManagement',
                    path:'CurriculumManagement',
                    component: () => import('@/views/CourseManagement/Routes/CurriculumManagement'),
                    meta: {
                        title: 'Curriculum Management'
                    }
                },
                {
                    name:'CourseManagement',
                    path:'CourseManagement',
                    component: () => import('@/views/CourseManagement/Routes/CourseManagement'),
                    meta: {
                        title: 'Course Management'
                    }
                },
                { 
                    //This is a fallback for if a route is not found above inside 'CourseManagement'
                    path:"", 
                    redirect:'CurriculumManagement' 
                }
            ]
        },
        {
            name: 'Tickets',
            path: '/Tickets',
            component: () => import('@/views/NavbarItems/Tickets'),
            meta: {
                title: 'Tickets'
            }
        },
        {
            name: 'MyTickets',
            path: '/MyTickets',
            component: () => import('@/views/NavbarItems/MyTickets'),
            meta: {
                title: 'My Tickets'
            }
        },
        {
            path:'/Settings',
            component:() => import('@/views/Settings/Settings'),
            meta:{
                requiresAuth:true,
            },
            children: [
                {
                    name: 'CommunicationTemplates',
                    path: 'CommunicationTemplates',
                    component: () => import('@/views/Settings/Tabs/CommunicationTemplates'),
                    meta: {
                        title: 'Communication Templates',
                    }
                },
                {
                    name: 'CourseFamilys',
                    path: 'CourseFamilys',
                    component: () => import('@/views/Settings/Tabs/CourseFamilys'),
                    meta: {
                        title: 'Course Families',
                    }
                },
                {
                    name: 'ManualEmailTemplates',
                    path: 'ManualEmailTemplates',
                    component: () => import('@/views/Settings/Tabs/ManualEmailTemplates'),
                    meta: {
                        title: 'Manual Email Templates',
                    }
                },
                {
                    path:'PermGroups',
                    name:'PermGroups',
                    component:() => import('@/views/Settings/Tabs/PermGroups'),
                    meta:{
                        title:'Permission Groups',
                    }
                },
                {
                    name: 'Schools',
                    path: 'Schools',
                    component: () => import('@/views/Settings/Tabs/Schools'),
                    meta: {
                        title: 'Schools',
                    }
                },
                {
                    name: 'SchoolSessions',
                    path: 'SchoolSessions',
                    component: () => import('@/views/Settings/Tabs/SchoolSessions'),
                    meta: {
                        title: 'School Sessions'
                    }
                },
                {
                    path:'SiteSettings',
                    name:'SiteSettings',
                    component:() => import('@/views/Settings/Tabs/SiteSettings'),
                    meta:{
                        title:'Settings',
                    }
                },
                {
                    name: 'Teachers',
                    path: 'Teachers',
                    component: () => import('@/views/Settings/Tabs/Teachers'),
                    meta: {
                        title: 'Teachers',
                    }
                },
                {
                    name: 'TicketTopics',
                    path: 'TicketTopics',
                    component: () => import('@/views/Settings/Tabs/TicketTopics'),
                    meta: {
                        title: 'Ticket Topics',
                    }
                },
                {
                    name: 'USBEReimbursements',
                    path: 'USBEReimbursements',
                    component: () => import('@/views/Settings/Tabs/USBEReimbursements'),
                    meta: {
                        title: 'USBE Reimbursements',
                    }
                },
                {
                    path:'Users',
                    component:() => import('@/views/Settings/Tabs/Users'),
                    children:[
                        {
                            path:'Employees',
                            name:'Employees',
                            component:() => import('@/views/Settings/Tabs/UserTabs/Employees'),
                            meta:{
                                title:'Employees Users',
                            }
                        },
                        {
                            path:'Students',
                            component:() => import('@/views/Settings/Tabs/UserTabs/Students'),
                            meta:{
                                title:'Student Users',
                            }
                        },
                        { 
                            //This is a fallback for if a route is not found above inside 'users'
                            path:"", 
                            redirect: 'Employees' 
                        },
                    ]
                },
                {
                    path:'Verbiage',
                    name:'Verbiage',
                    component:() => import('@/views/Settings/Tabs/Verbiage'),
                    meta:{
                        title:'Verbiage'
                    }
                },
                { 
                    //This is a fallback for if a route is not found above inside 'settings'
                    path:"", 
                    redirect: 'SiteSettings' 
                },
            ]
        },
        {
            path:'/SiteActivity',
            name:'SiteActivity',
            component:() => import('@/views/NavbarItems/SiteActivity'),
            meta:{
                requiresAuth:true,
                title:'Site Activity'
            },
        },
        {
            name: 'Documentation',
            path: '/Documentation',
            component: () => import('@/views/NavbarItems/Documentation'),
            meta: {
                title: 'Documentation',
                requiresAuth: true
            }
        },

        /*Internal Site Links*/
        {
            name: 'AccountHome',
            path: '/AccountHome',
            component: () => import('@/views/AccountHome/AccountHome'),
            props: true,
            meta: {
                title: 'Account Home',
                requiresAuth: true
            }
        },
        {
            name: 'StudentLookup',
            path: '/StudentLookup',
            component: () => import('@/views/CreateAccount/StudentLookup'),
            meta: {
                title: 'Create Account',
                requiresAuth: true
            }
        },
        {
            name: 'CreateAccountFromSkyward',
            path: '/CreateAccountFromSkyward',
            component: () => import('@/views/CreateAccount/CreateAccountFromSkyward'),
            props: true,
            meta: {
                title: 'Create Account',
                requiresAuth: true
            }
        },
        {
            name: 'CreateAccountFull',
            path: '/CreateAccountFull',
            component: () => import('@/views/CreateAccount/CreateAccountFull'),
            meta: {
                title: 'Create Account'
            }
        },
        {
            name: 'LoggedInRedirect',
            path: '/LoggedInRedirect',
            component: () => import('@/views/LoggedInRedirect'),
            meta: {
                title: 'Logged In Redirect'
            }
        },

        /*External Site Links*/
        {
            name: 'AccessTokenLogin',
            path: '/AccessTokenLogin',
            component: () => import('@/views/AccessTokenLogin'),
            meta: {
                title: 'Login'
            }
        },
        {
            name: 'login',
            path: '/login',
            component: () => import('@/views/Login'),
            props: true,
            meta: {
                title: 'Login',
                loggedInRedirect: "/LoggedInRedirect"
            }
        },
        {
            name: 'NoPerm',
            path: '/NoPerm',
            component: () => import('@/views/NoPerm'),
            props: true,
            meta: {
                title: 'No Permission',
                requiresAuth: true
            }
        },
        {
            name: 'HealthConsentForm',
            path: '/HealthConsentForm',
            component: () => import('@/views/ConsentForms/HealthConsentForm'),
            meta: {
                title: 'Health Consent Form'
            },
        },
        {
            name: 'FitnessConsentForm',
            path: '/FitnessConsentForm',
            component: () => import('@/views/ConsentForms/FitnessConsentForm'),
            meta: {
                title: 'Fitness Consent Form'
            },
        },
        {
            name: 'FastTrackConsentForm',
            path: '/FastTrackConsentForm',
            component: () => import('@/views/ConsentForms/FastTrackConsentForm'),
            meta: {
                title: 'Fast Track Consent Form'
            },
        },
        //Consent Form's redirect here on bad token
        {
            name: 'BadToken',
            path: '/BadToken',
            component: () => import('@/views/ConsentForms/BadToken'),
            meta: {
                title: 'Page Not Found'
            },
        },
        //Consent Form's redirect here on successful sign
        {
            name: 'SuccessSign',
            path: '/SuccessSign',
            props: true,
            component: () => import('@/views/ConsentForms/SuccessSign'),
            meta: {
                title: 'Success Sign'
            },
        },

        {
            path: '/Dev',
            name: 'Dev',
            component: () => import('@/views/Dev'),
            meta: {
                title: 'Dev',
            }
        },


        { 
            //This is a fallback for if a route is not found above
            path: "*", 
            redirect: '/login' 
        },
    ]
});


router.beforeEach((to, from, next) => {
    //Add to the page's title
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if(nearestWithTitle.meta.title) {
        if(document.title.indexOf('|') >= 0)
            document.title = document.title.split(' | ')[0] + ' | ' + nearestWithTitle.meta.title;
        else
            document.title = ' | ' + nearestWithTitle.meta.title;
    }

    var calculateLoggedInRedirect = function() {
        // See if we redirect if logged in, and if so to where
        if(to.matched.some(record => record.meta.loggedInRedirect)) {
            for(var i=0; i<to.matched.length; i++) {
                if(to.matched[i].meta.loggedInRedirect){
                    var redirectLocation = to.matched[i].meta.loggedInRedirect;
                    if(redirectLocation == '/Home'){
                        if(store.state.me.IsGuardian == 1) redirectLocation = '/NoPerm';
                        else if(store.state.me.IsStudent != 1){
                            if(store.state.me.UserRole == 'Teacher'){
                                if(store.state.me.Permissions.hascan_view_accounts_assigned_to_H) redirectLocation = '/AccountCoursesDashboard';
                                else redirectLocation = '/EMAccountDashboard';
                            }
                            else redirectLocation = '/AccountDashboard';
                        }
                        else redirectLocation = '/AccountHome';
                    }
                    // We need to redirect if we are logged in
                    if(!store.state.me.initialized) {
                        store.dispatch('me/getMe').then(() => {
                            if(store.state.me.ID) next({path: redirectLocation});
                            else next();
                        });
                    }
                    else if(store.state.me.ID) next({path: redirectLocation});
                    else next();
                }
            }
        }
        else next();
    }

    // See if we're authenticated - see https://scotch.io/tutorials/vue-authentication-and-route-handling-using-vue-router
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if(!store.state.me.initialized) {
            store.dispatch('me/getMe').then(() => {
                if(!store.state.me.ID) {
                    // Authentication not met
                    store.dispatch('alert/failure', "You must be logged in to view this page");
                    next({name:'login',params:{name:to.name,query:to.query}});
                }
                else next(); // Authenticated
            });
        }
        else{
            if(!store.state.me.ID) {
                // Authentication not met
                store.dispatch('alert/failure', "You must be logged in to view this page");
                next({name:'login',params:{name:to.name,query:to.query}});
            }
            else next(); // Authenticated
        }
    }
    else calculateLoggedInRedirect(); // No route auth metadata
});

export default router
