<template>
<li>
    <a v-if="me.ID > 0" id="Account-Circle" class="circle-button" @click="toggleAccount" :class="{'active':toggled}">
        <span>{{me.FirstName.charAt(0)}}{{me.LastName.charAt(0)}}</span>
        <span class="circle-button-label">Account</span>
    </a>
    <div v-if="me.ID > 0" id="Account-Actions" class="floating-menu" :class="{'toggled':toggled}">
        <ul>
            <li>
                <p>
                    <strong>{{me.FirstName}} {{me.LastName}}</strong>
                    <!-- <br>{{me.EmployeeID}} -->
                </p>
                <ul>
                    <li><a @click="logout">Logout</a></li>
                    <li v-if="me.Impersonated"><a @click="logout">Stop Impersonating</a></li>
                    <li v-if="me.Permissions.hascan_impersonate_user">
                        <router-link to="/Settings/Users">Switch Users</router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
    <div v-else-if="me.ID" class="Button is-warning is-filled" @click="logout()">
        Logout
    </div>
</li>
</template>

<script>
import { mapState, mapActions } from "vuex";
import router from "@/router";

import siteUserApi from "@/api/boilerplate/siteUser.js";

export default {
    name:'Account',
    props:{

    },
    components:{

    },
    computed:{
        ...mapState({
            me: (state) => state.me,
        }),
        HomeRoute(){
            if(this.me.ID > 0 && this.me.IsStudent != 1 && this.me.IsGuardian != 1){
                if(this.me.UserRole == 'Teacher'){
                    if(this.me.Permissions.hascan_view_accounts_assigned_to_H) return 'AccountCoursesDashboard';
                    else return 'EMAccountDashboard';
                }
                else return 'AccountDashboard';
            }
            else if(this.me.ID > 0 && this.me.IsStudent == 1) return 'AccountHome';
            else if(this.me.ID > 0 && this.me.IsGuardian == 1) return 'NoPerm';
            else return 'login';
        }
    },
    data(){
        return {
            toggled:false,
        };
    },
    watch:{
        $route(){
            this.toggled = false;
        }
    },
    mounted(){
        document.addEventListener('click', this.close);
    },
    beforeDestroy(){
        document.removeEventListener('click', this.close);
    },
    methods:{
        ...mapActions({
            getSiteSettings: "siteSettings/get",
            getMe: "me/getMe",
        }),
        close(event){
            if(this.toggled && !this.$el.contains(event.target)) this.toggled = false;
        },
        toggleAccount(){
            this.toggled = !this.toggled;
        },
        logout(){
            if(this.me.Impersonated){
                siteUserApi.stopImpersonating().then(() => {
                    this.getMe().then(() => {
                        for(let key in sessionStorage){
                            sessionStorage.removeItem(key);
                        }
                        router.go(this.HomeRoute);
                    });
                });
            } else {
                siteUserApi.logout().then(() => {
                    this.getSiteSettings();
                    this.getMe().then(() => {
                        router.push('/login');
                    });
                });
            }
            this.toggled = false;
        }
    }
};
</script> 