<template>
<li class="app-nav-button">
    <a :href="url">
        <font-awesome-icon v-if="isFontAwesome" :icon="['fad', faIconName]" :style="{color:color}" />
        <img v-else-if="icon" :src="imageUrlBase+'/public/client/img/'+icon">
        <span>{{ name }}</span>
    </a>
</li>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFile } from "@fortawesome/pro-solid-svg-icons";

export default {
    name:'AppButton',
    props:{
        name:{},
        url:{},
        icon:{},
        color:{},
        imageUrlBase:{},
    },
    components:{
        FontAwesomeIcon,
    },
    computed:{
        isFontAwesome(){
            return /^fa[a-zA-Z]*$/.test(this.icon);
        },
        faIconName(){
            return this.icon.replace(/[A-Z]/g, (m) => "-" + m.toLowerCase()).substring(3);
        }
    },
    data(){
        return {
            fetchedIcon: faFile,
        };
    }
};
</script> 