<template>
    <div class="Alerts">
        <alert-item v-for="alert in alerts" :key="alert.timestamp" :timeout="alert.time" :timestamp="alert.timestamp"
            :message="alert.message" :type="alert.type"></alert-item>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AlertItem from '@/components/alert/AlertItem.vue'
export default {
    name: 'Alert',
    components: {
        AlertItem
    },
    computed: {
        ...mapState({
            alerts: state => state.alert.alerts
        }),
    }    
}
</script>
