<template>
    <!-- uses router-link for internal routing, a tag for external -->

    <!-- In-site top-level link with path specified --->
    <li v-if="!/https?/.test(path) && !isChild && path" :class="{ 'has-sub-menu':children.length }">
        <router-link
            :to="path"
            class="top-menu-item"
            custom v-slot="{href,navigate,isActive,isExactActive}">
            <a :href="shouldRoute ? href : null"
                @click="($event)=>{shouldRoute ? navigate($event) : subMenuToggle() }"
                :class="{ 'sub-menu-expanded':subMenuExpanded && !shouldRoute, 'router-link-active':isActive, 'router-link-exact-active':isExactActive }">
                <font-awesome-icon :icon="icon" />
                <span class="nav-label">{{ label }} <span v-if="tag" class="tag is-accent">{{ tag }}</span></span>
            </a>
        </router-link>
        <ul v-if="children.length" :class="{'sub-menu': !isChild}">
            <NavItem
                v-for="item in children"
                :key="item.label"
                :path="item.path"
                :label="item.label"
                :tag="item.tag"
                :children="item.children"
                :is-child="true"
                :disabled="item.disabled" />
        </ul>
    </li>

    <!-- Outside top-level link OR no path specified --->
    <li v-else-if="!isChild && (/https?/.test(path) || !path)" :class="{ 'has-sub-menu':children.length }">
        <a :href="shouldRoute && path ? path : null"
            @click="($event)=>{shouldRoute ? navigate($event) : subMenuToggle() }"
            class="top-menu-item"
            :class="{ 'sub-menu-expanded':subMenuExpanded && !shouldRoute, 'router-link-active':isActive, 'router-link-exact-active':isExactActive }">
            <font-awesome-icon :icon="icon" />
            <span class="nav-label">{{ label }}</span>
        </a>
        <nav v-if="children.length" :class="{'sub-menu': !isChild}">
            <NavItem
                v-for="item in children"
                :key="item.label"
                :path="item.path"
                :label="item.label"
                :tag="item.tag"
                :children="item.children"
                :is-child="true"
                :disabled="item.disabled" />
        </nav>
    </li>

    <!-- Child link --->
    <li v-else-if="isChild" :class="{ 'has-sub-menu': children.length }">
        <router-link v-if=" !/https?/.test(path) && path"
            :to="path"
            class=""
            @click="($event)=>{shouldRoute ? navigate($event) : '' }">
            {{label}}
        </router-link>
        <a v-else class=""
            :href="path"
            @click="($event)=>{shouldRoute ? navigate($event) : '' }">
            {{label}}
        </a>
        <ul v-if="children.length" class="">
            <!-- Children of sub-menu items -->
            <NavItem
                v-for="item in children"
                :key="item.label"
                :path="item.path"
                :label="item.label"
                :tag="item.tag"
                :children="item.children"
                :is-child="true"
                :disabled="item.disabled" />
        </ul>
    </li>

</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFile } from "@fortawesome/pro-solid-svg-icons";

export default {
    name: "NavItem",
    components: {
        FontAwesomeIcon,
    },
    props: {
        label: {},
        path: {},
        icon: {
            default: () => {
                return faFile;
            },
        },
        tag: {},
        children: {
            type: Array,
            default: () => [],
        },
        isChild: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        windowWidth: {
            type: Number,
            default: window.innerWidth,
        },
    },
    computed: {
        shouldRoute() {
            // on mobile menu, top-level menu items with children need to expand the child menu instead of routing
            // disabled items shouldn't route
            return (this.windowWidth > 769 || !this.children.length) && this.path && !this.disabled;
        },
    },
    data() {
        return {
            fetchedIcon: faFile,
            subMenuExpanded: false,
            isActive: false,
            isExactActive: false,
        };
    },
    methods: {
        subMenuToggle() {
            this.subMenuExpanded = !this.subMenuExpanded;
        },
    },
    watch: {
        $route() {
            this.subMenuExpanded = false;
        },
    },
};
</script>