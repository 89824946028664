const state = {
    alerts: []
}

const getters = {

}

const actions = {
    closeAlert({ commit }, index=0) {
        commit('closeAlert', index);
    },
    success({ commit }, message) {
        commit('insert', {message: message, type: 'success'});
    },
    failure({ commit }, message) {
        commit('insert', {message: message, type: 'danger'});
    }
}

const mutations = {
    insert(state, payload) {
        var newAlert = {
            message: 'Oops! An error occurred, please check your network connection and try again',
            type: 'danger',
            time: 12000,
            timestamp: Date.now()
        }
        
        if(payload.message) newAlert.message = payload.message;
        if(payload.type) newAlert.type = payload.type;
        if(payload.time) newAlert.time = payload.time;

        state.alerts.push(newAlert);
    },
    closeAlert(state, stampToRemove) {
        var index = -1;
        for(var i=0; i<state.alerts.length; i++) {
            if(state.alerts[i].timestamp === stampToRemove) {
                index = i;
                break;
            }
        }
        if(index >= 0)
            state.alerts.splice(index, 1);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}