import codeTableApi from '@/api/LocalSettings/codeTable.js';

const state = {
    Codes:[]
}

const getters = {

}

const actions = {
    async getCodes({commit,state},payload){
        let index = state.Codes.findIndex(e=>{
            return e.FieldName == payload.FieldName && e.TableName == payload.TableName;
        });
        if(index >= 0) return state.Codes[index].collection;
        else{
            let response = await codeTableApi.getCodes(payload.TableName,payload.FieldName);
            commit('pushCodes', {
                FieldName:payload.FieldName,
                TableName:payload.TableName,
                collection:response
            });
            return response;
        }
    }
}

const mutations = {
    pushCodes(state, payload) {
        state.Codes.push(payload);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}