const state = {
    numActiveRequests: 0
}

const getters = {
    isLoading() {
        return state.numActiveRequests !== 0;
    }
}

const actions = {
    loadingOn({ commit }) {
        commit('loadingOn');
    },
    loadingOff({ commit }) {
        commit('loadingOff');
    },
    loadingReset({ commit }) {
        commit('loadingReset');
    },
}

const mutations = {
    loadingOn() {
        state.numActiveRequests = state.numActiveRequests+1;
    },
    loadingOff() {
        if(state.numActiveRequests > 0)
            state.numActiveRequests = state.numActiveRequests-1;
    },
    loadingReset() {
        state.numActiveRequests = 0;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}