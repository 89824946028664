<template>
<header>
<!-- SITE IDENTITY  -->
<div class="Top-Bar">
    <div id="Sidebar-Header">
        <!-- Top Left -->
        <span id="sidebar-menu-toggle" :class="{toggled: sidebar.toggled}" class="circle-button" @click="toggleSidebar">
            <font-awesome-icon :icon="sidebarIcon" />
        </span>
        <a id="district-link" href="http://www.canyonsdistrict.org">
            <img src="~@csd/styling/dist/img/color-horizontal-small.png" alt="CSD Logo" />
        </a>
    </div>
    <div id="Site-Title">
        <!-- Top Center -->
        <span>{{siteSettings.SiteName}}</span>
    </div>
    <div>
        <!-- Top Right -->
        <ul id="Key-Actions">
            <li class="mobile-only">
                <router-link :to="HomeRoute" class="circle-button">
                    <font-awesome-icon :icon="faHome" />
                    <span class="circle-button-label">Home</span>
                </router-link>
            </li>
            <li>
                <a  v-if="me.ID > 0 && !me.IsStudent && !me.IsGuardian" href="#" @click="goToDocumentation()" class="circle-button">
                    <font-awesome-icon :icon="faQuestion" />
                    <span class="circle-button-label">Help</span>
                </a>
            </li>
            <li v-if="canAccessSettingsTab">
                <router-link to="/Settings" class="circle-button">
                    <font-awesome-icon :icon="faCog" />
                    <span class="circle-button-label">Settings</span>
                </router-link>
            </li>
            <AppsNav v-if="me.ID > 0" />
        </ul>
        <span v-if="!me.ID">
            <div class="Button is-neutral is-filled" @click="goToSSOLogin()">
                Login&nbsp;&nbsp;
                <font-awesome-icon :icon="faLock" />
            </div>
        </span>
        <ul>
            <Account v-if="me.ID" />
        </ul>
    </div>
</div>

<!-- SITEWIDE MESSAGES -->
<div class="Site-Messages">
    <p v-if="siteSettings.SkywardDown" class="Help">
        <font-awesome-icon :icon="faExclamationTriangle" class="is-color-warning" />
        <span>Skyward is down for maintenance. Some features of this site will not work. We apologize for the inconvenience.</span>
    </p>
</div>
</header>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AppsNav from "@/components/header/AppsNav/AppsNav.vue";
import Account from "@/components/header/Account.vue";
import { faExclamationTriangle, faBars, faTh, faQuestion, faCog, faHome, faLock } from "@fortawesome/pro-solid-svg-icons";
import router from '@/router';

export default {
    name:'Header',
    components:{
        AppsNav,
        FontAwesomeIcon,
        Account
    },
    computed:{
        ...mapState({
            siteSettings: (state) => state.siteSettings,
            me: (state) => state.me,
            sidebar: (state) => state.sidebar
        }),
        canAccessSettingsTab(){
            return (
                !this.me.IsStudent &&
                !this.me.IsGuardian &&
                (this.me.Permissions.hascan_edit_site_settings ||
                    //this.me.Permissions.hascan_view_users ||
                    this.me.Permissions.hascan_view_groups ||
                    this.me.Permissions.hascan_update_verbiage)
            );
        },
        HomeRoute(){
            if(this.me.ID > 0 && this.me.IsStudent != 1 && this.me.IsGuardian != 1){
                if(this.me.UserRole == 'Teacher'){
                    if(this.me.Permissions.hascan_view_accounts_assigned_to_H) return 'AccountCoursesDashboard';
                    else return 'EMAccountDashboard';
                }
                else return 'AccountDashboard';
            }
            else if(this.me.ID > 0 && this.me.IsStudent == 1) return 'AccountHome';
            else if(this.me.ID > 0 && this.me.IsGuardian == 1) return 'NoPerm';
            else return 'login';
        }
    },
    data(){
        return {
            faExclamationTriangle:faExclamationTriangle,
            faBars,
            faQuestion,
            faCog,
            faTh,
            faLock,
            sidebarIcon:faBars,
            faHome,
            appsNavToggled:false
        };
    },
    methods:{
        ...mapActions({
            getMe: "me/getMe",
            getSiteSettings: "siteSettings/get",
            toggleSidebar: "sidebar/toggleSidebar",
            modalOpen: "modal/modalOpen",
            alertFailure: 'alert/failure'
        }),
        goToDocumentation(){
            router.push({name:'Documentation',params:{}});
        },
        goToSSOLogin(){
            var navigateToLoginPage = (CSDLoginURL) => {
                if(CSDLoginURL){ // SSO enabled
                    const refUrl = location.protocol + '//' + location.host + '/' + router.resolve('LoggedInRedirect').href;
                    window.location.href = CSDLoginURL + "?refUrl=" + encodeURIComponent(refUrl);
                } 
                else this.alertFailure('Unable to access login page. Database connection is down.');
            }

            // Check siteSettings to know if SSO is enabled and reroute if necessary
            if(!this.siteSettings.initialized){
                this.getSiteSettings.then(() => {
                    navigateToLoginPage(this.siteSettings.CSDLoginURL);
                });
            }
            else navigateToLoginPage(this.siteSettings.CSDLoginURL);
        }
    },
    watch:{
        $route(){
            if (window.innerWidth < 769){
                // mobile menu should close on route change
                // sidebar expansion on desktop should stay the same
                this.toggleSidebar(false);
            }
        }
    }
};
</script>