<template>
<li class="apps-nav-group">
    <span class="group-label">{{ name }}</span>
    <ul class="group-apps">
        <AppButton
            v-for="app in FilteredApps"
            :key="app.ID"
            :name="app.DistrictSite.SiteName"
            :url="app.DistrictSite.SiteURL"
            :icon="app.DistrictSite.IconURL"
            :color="app.DistrictSite.SiteColor"
            :imageUrlBase="imageUrlBase" />
    </ul>
</li>
</template>

<script>
import AppButton from "@/components/header/AppsNav/AppButton.vue";

export default {
    name:"AppGroup",
    components:{
        AppButton
    },
    props:{
        name:{},
        apps:{
            type:Array,
            default:() => [],
        },
        imageUrlBase:{}
    },
    computed:{
        FilteredApps(){
            let MySearch = this.SearchAll.toUpperCase();
            if(this.SearchAll == '') return this.apps;
            return this.apps.filter(e=>{
                return e.DistrictSite.SiteName.toUpperCase().indexOf(MySearch) >= 0;
            });
        }
    },
    data(){
        return {
            SearchAll:''
        };
    },
    created(){

    },
    methods:{
        reProp(NewSearchAll){
            this.SearchAll = NewSearchAll;
        }
    }
};
</script>