<template>
<div v-if="modalState.visible" class="Overlay">
    <div @click="closeFromBackgroundClick()" class="Background"></div>
    <!--Login-->
    <forgot-password-lookup-student v-if="modalState.name == 'ForgotPasswordLookupStudent'"></forgot-password-lookup-student>

    <!--Account Home-->
        <policy-and-services-verify v-if="modalState.name == 'PolicyAndServicesVerify'" :propAccount="modalState.data.propAccount"></policy-and-services-verify>

        <!--Courses-->
        <add-account-course v-if="modalState.name == 'AddAccountCourse'" :propAccount="modalState.data.propAccount"></add-account-course>
        <view-account-course-history v-if="modalState.name == 'ViewAccountCourseHistory'" :propAccountCourse="modalState.data.propAccountCourse"></view-account-course-history>
        <send-denied-email v-if="modalState.name == 'SendDeniedEmail'" :propAccountCourse="modalState.data.propAccountCourse"></send-denied-email>

        <!--Tickets (Tab)-->
        <add-account-ticket v-if="modalState.name == 'AddAccountTicket'" :propAccount="modalState.data.propAccount"></add-account-ticket>
        
        <!--Profile-->
        <student-lookup-for-update v-if="modalState.name == 'StudentLookupForUpdate'" :propAccount="modalState.data.propAccount"></student-lookup-for-update>
        <to-be-updated-from-skyward v-if="modalState.name == 'ToBeUpdatedFromSkyward'" :propProfile="modalState.data.propProfile" :propAccount="modalState.data.propAccount"></to-be-updated-from-skyward>
        
        <!--Emails-->
        <view-email-content v-if="modalState.name == 'ViewEmailContent'" :propEmailBody="modalState.data.propEmailBody"></view-email-content>
        <send-email v-if="modalState.name == 'SendEmail'" :propAccount="modalState.data.propAccount"></send-email>

        <!--Communications-->
        <add-communication v-if="modalState.name == 'AddCommunication'" :propAccount="modalState.data.propAccount"></add-communication>
        <edit-communication v-if="modalState.name == 'EditCommunication'" :propID="modalState.data.propID" :propAccount="modalState.data.propAccount"></edit-communication>

        <!--Attendance-->
        <add-attendance v-if="modalState.name == 'AddAttendance'" :propAccount="modalState.data.propAccount" :propAccountCourseID="modalState.data.propAccountCourseID"></add-attendance>

    <!--Account Dashboard-->
    <send-mass-email v-if="modalState.name == 'SendMassEmail'" :StudentNumbers="modalState.data.StudentNumbers"></send-mass-email >
    <update-credits-info v-if="modalState.name == 'UpdateCreditsInfo'"></update-credits-info>
    <mass-add-communication v-if="modalState.name == 'MassAddCommunication'" 
        :propFilter="modalState.data.propFilter" :propType="modalState.data.propType" :propCount="modalState.data.propCount" :propSelected="modalState.data.propSelected"
    ></mass-add-communication>

    <!--Account Courses Dashboard-->
    <export-transcript-labels v-if="modalState.name == 'ExportTranscriptLabels'"></export-transcript-labels>
    <import-enrollment-report v-if="modalState.name == 'ImportEnrollmentReport'"></import-enrollment-report>
    <manage-teacher-compensation v-if="modalState.name == 'ManageTeacherCompensation'"></manage-teacher-compensation>
    <mass-add-attendance v-if="modalState.name == 'MassAddAttendance'" 
        :propFilter="modalState.data.propFilter" :propCount="modalState.data.propCount" :propSelected="modalState.data.propSelected"
    ></mass-add-attendance>

    <!--Course Management-->
    <curriculum-registrants v-if="modalState.name == 'CurriculumRegistrants'" :Curriculum="modalState.data.Curriculum" :Year="modalState.data.Year"></curriculum-registrants>
    <curriculum-form v-if="modalState.name == 'CurriculumForm'" :propCurriculumID="modalState.data.propCurriculumID" :propSchoolYear="modalState.data.propSchoolYear"></curriculum-form>
    <update-skyward-fields v-if="modalState.name == 'UpdateSkywardFields'"></update-skyward-fields>
    <add-curriculum-form v-if="modalState.name == 'AddCurriculumForm'"></add-curriculum-form>
    <build-courses-from-skyward v-if="modalState.name == 'BuildCoursesFromSkyward'"></build-courses-from-skyward>
    <build-accelerate-from-courses v-if="modalState.name == 'BuildAccelerateFromCourses'"></build-accelerate-from-courses>

    <!--Tickets (Dashboard)-->
    <ticket-form v-if="modalState.name == 'TicketForm'" :propID="modalState.data.propID"></ticket-form>

    <!--My Tickets-->
    <add-teacher-ticket v-if="modalState.name == 'AddTeacherTicket'" :propTeacher="modalState.data.propTeacher"></add-teacher-ticket>

    <!--Settings-->
        <!--Communication Templates-->
        <edit-communication-template v-if="modalState.name == 'EditCommunicationTemplate'" :propID="modalState.data.propID"></edit-communication-template>
        <create-communication-template v-if="modalState.name == 'CreateCommunicationTemplate'"></create-communication-template>
        <!--Course Families-->
        <edit-course-family v-if="modalState.name == 'EditCourseFamily'" :propID="modalState.data.propID"></edit-course-family>
        <create-course-family v-if="modalState.name == 'CreateCourseFamily'"></create-course-family>
        <!--Manual Email Templates-->
        <manual-email-templates-form v-if="modalState.name == 'ManualEmailTemplatesForm'" :propID="modalState.data.propID"></manual-email-templates-form>
        <!--Permission Groups-->
        <perm-group-form v-if="modalState.name == 'PermGroupForm'" :propID="modalState.data.propID"></perm-group-form>
        <!--Schools-->
        <school-form v-if="modalState.name == 'SchoolForm'" :propID="modalState.data.propID"></school-form>
        <!--School Sessions-->
        <school-session-form v-if="modalState.name == 'SchoolSessionForm'" :propID="modalState.data.propID"></school-session-form>
        <!--Teachers-->
        <edit-teacher-form v-if="modalState.name == 'EditTeacherForm'" :propID="modalState.data.propID"></edit-teacher-form>
        <add-teacher-form v-if="modalState.name == 'AddTeacherForm'"></add-teacher-form>
        <!--Ticket Topics-->
        <ticket-topic-form v-if="modalState.name == 'TicketTopicForm'" :propID="modalState.data.propID"></ticket-topic-form>
        <!--USBEReimbursements-->
        <create-u-s-b-e-reimbursement v-if="modalState.name == 'CreateUSBEReimbursement'"></create-u-s-b-e-reimbursement>
        <edit-u-s-b-e-reimbursement v-if="modalState.name == 'EditUSBEReimbursement'" :propID="modalState.data.propID"></edit-u-s-b-e-reimbursement>
        <!--Users-->
        <site-user-form v-if="modalState.name == 'SiteUserForm'" 
            :propCSDNameID="modalState.data.propCSDNameID" :propSiteUserID="modalState.data.propSiteUserID" :propIsStudent="modalState.data.propIsStudent"
        ></site-user-form>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name:'Modal',
    components:{
        //Login
        ForgotPasswordLookupStudent: () => import('@/components/modal/Login/ForgotPasswordLookupStudent.vue'),

        //Account Home
            PolicyAndServicesVerify: () => import('@/components/modal/AccountHome/PolicyAndServicesVerify.vue'),

            //Account Courses Tab
            AddAccountCourse: () => import('@/components/modal/AccountHome/AccountCoursesTab/AddAccountCourse.vue'),
            ViewAccountCourseHistory: () => import('@/components/modal/AccountHome/AccountCoursesTab/ViewAccountCourseHistory.vue'), //Also used on Account Courses Dashboard
            SendDeniedEmail: () => import('@/components/modal/AccountHome/AccountCoursesTab/SendDeniedEmail.vue'), //Also used on Account Courses Dashboard

            //Account Tickets Tab
            AddAccountTicket: () => import('@/components/modal/AccountHome/AccountTicketsTab/AddAccountTicket.vue'),

            //Account Profile Tab
            StudentLookupForUpdate: () => import('@/components/modal/AccountHome/AccountProfileTab/StudentLookupForUpdate.vue'),
            ToBeUpdatedFromSkyward: () => import('@/components/modal/AccountHome/AccountProfileTab/ToBeUpdatedFromSkyward.vue'),

            //Account Email History Tab
            ViewEmailContent: () => import('@/components/modal/AccountHome/AccountEmailHistoryTab/ViewEmailContent.vue'),
            SendEmail: () => import('@/components/modal/AccountHome/AccountEmailHistoryTab/SendEmail.vue'),

            //Communications Tab
            AddCommunication: () => import('@/components/modal/AccountHome/AccountCommunicationsTab/AddCommunication.vue'),
            EditCommunication: () => import('@/components/modal/AccountHome/AccountCommunicationsTab/EditCommunication.vue'),

            //Communications Tab
            AddAttendance: () => import('@/components/modal/AccountHome/AccountAttendanceTab/AddAttendance.vue'),

        //Account Dashboard
        SendMassEmail: () => import('@/components/modal/AccountDashboard/SendMassEmail.vue'),
        UpdateCreditsInfo: () => import('@/components/modal/AccountDashboard/UpdateCreditsInfo.vue'),
        MassAddCommunication: () => import('@/components/modal/AccountDashboard/MassAddCommunication.vue'),

        //Account Courses Dashboard
        ExportTranscriptLabels: () => import('@/components/modal/AccountCoursesDashboard/ExportTranscriptLabels.vue'),
        ImportEnrollmentReport: () => import('@/components/modal/AccountCoursesDashboard/ImportEnrollmentReport.vue'),
        ManageTeacherCompensation: () => import('@/components/modal/AccountCoursesDashboard/ManageTeacherCompensation.vue'),
        MassAddAttendance: () => import('@/components/modal/AccountCoursesDashboard/MassAddAttendance.vue'),

        //Course Management
        CurriculumRegistrants: () => import('@/components/modal/CourseManagement/CurriculumRegistrants.vue'),
        CurriculumForm: () => import('@/components/modal/CourseManagement/CurriculumCourseSectionModal/CurriculumForm.vue'),
        UpdateSkywardFields: () => import('@/components/modal/CourseManagement/UpdateSkywardFields.vue'),
        AddCurriculumForm: () => import('@/components/modal/CourseManagement/AddCurriculumForm.vue'),
        BuildCoursesFromSkyward: () => import('@/components/modal/CourseManagement/BuildCoursesFromSkyward.vue'),
        BuildAccelerateFromCourses: () => import('@/components/modal/CourseManagement/BuildAccelerateFromCourses.vue'),

        //Tickets
        TicketForm: () => import('@/components/modal/Tickets/TicketForm.vue'),

        //My Tickets
        AddTeacherTicket: () => import('@/components/modal/MyTickets/AddTeacherTicket.vue'),

        //Settings
            //Communication Templates
            EditCommunicationTemplate: () => import('@/components/modal/Settings/CommunicationTemplates/EditCommunicationTemplate.vue'),
            CreateCommunicationTemplate: () => import('@/components/modal/Settings/CommunicationTemplates/CreateCommunicationTemplate.vue'),
            //Course Families
            EditCourseFamily: () => import('@/components/modal/Settings/CourseFamilys/EditCourseFamily.vue'),
            CreateCourseFamily: () => import('@/components/modal/Settings/CourseFamilys/CreateCourseFamily.vue'),
            //Manual Email Templates
            ManualEmailTemplatesForm: () => import('@/components/modal/Settings/ManualEmailTemplates/ManualEmailTemplatesForm.vue'),
            //Permission Groups
            PermGroupForm: () => import('@/components/modal/Settings/PermGroups/PermGroupForm'),
            //Schools
            SchoolForm: () => import('@/components/modal/Settings/Schools/SchoolForm.vue'),
            //School Sessions
            SchoolSessionForm: () => import('@/components/modal/Settings/SchoolSessions/SchoolSessionForm.vue'),
            //Teachers
            EditTeacherForm: () => import('@/components/modal/Settings/Teachers/EditTeacherForm.vue'),
            AddTeacherForm: () => import('@/components/modal/Settings/Teachers/AddTeacherForm.vue'),
            //Ticket Topics
            TicketTopicForm: () => import('@/components/modal/Settings/Tickets/TicketTopicForm.vue'),
            //USBEReimbursements
            CreateUSBEReimbursement: () => import('@/components/modal/Settings/USBEReimbursements/CreateUSBEReimbursement.vue'),
            EditUSBEReimbursement: () => import('@/components/modal/Settings/USBEReimbursements/EditUSBEReimbursement.vue'),
            //Users
            SiteUserForm: () => import('@/components/modal/Settings/Users/SiteUserForm')
    },
    computed:{
        ...mapState({
            modalState: state => state.modal
        })
    },
    methods:{
        ...mapActions({
            modalClose: 'modal/modalClose'
        }),
        closeFromBackgroundClick(){
            //Restrict modal closing on PolicyAndServicesVerify
            if(this.modalState.name != 'PolicyAndServicesVerify'){
                this.modalClose();
            }
        }
    }
}
</script>