<template>
    <div class="Alert Slide-down">
        <div :class="{'is-bg-success': type == 'success', 'is-bg-danger': type == 'danger'}" class="Body">
            <button @click="closeAlert(timestamp)" class="delete"></button>
            <p>{{message}}</p>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'AlertItem',
    props: {
        message: String,
        type: String,
        timeout: Number,
        timestamp: Number
    },
    methods: {
        ...mapActions({
            closeAlert: 'alert/closeAlert'
        }),
    },
    created(){
        var self = this;
        if(self.timeout) {
            setTimeout(function() {
                self.closeAlert(self.timestamp);
            }, self.timeout)
        }
    }
}
</script>
