import http from '@/api/http';

const handlerUrl = 'boilerplate/siteSettings/';

export default {
    get(){
        return http.get(handlerUrl+'get').then((response) => http.getResponseData(response));
    },
    update(SiteSettings){
        return http.put(handlerUrl+'update',{SiteSettings:SiteSettings}).then((response) => http.getResponseData(response));
    }
}