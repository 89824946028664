<template>
<li>
    <a href="#" class="circle-button" @click="toggleAppsNav" :class="{'active':toggled}">
        <font-awesome-icon :icon="faTh" />
        <span class="circle-button-label">CSD Apps</span>
    </a>
    <div id="Apps-Nav" class="floating-menu" :class="{'toggled':toggled}">
        <ul id="Apps-Nav-List">
            <li>
                <input @keyup="runSearch()" @search="runSearch()" v-model="SearchAll" placeholder="Search" class="input Apps-Nav-Search" type="search"/>
                <p class="Apps-Nav-Edit">
                    <a href="https://webportal.canyonsdistrict.org/#/Home?EditMode=1" v-tooltip="'Customize Menu'">
                        <span class="icon">
                            <font-awesome-icon :icon="faPencilAlt" />
                        </span>
                    </a>
                </p>
            </li>
            <AppGroup
                v-for="group in appGroups"
                ref="AppGroup"
                :key="group.id"
                :name="group.Name"
                :apps="group.Tiles"
                :imageUrlBase="'https://webportal.canyonsdistrict.org'"
            />
        </ul>
    </div>
</li>
</template>

<script>
import AppGroup from "@/components/header/AppsNav/AppGroup.vue";
import { faTh, faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import siteUserApi from "@/api/boilerplate/siteUser.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VTooltip as Tooltip } from "v-tooltip";

export default {
    name:'AppsNav',
    directives:{
        Tooltip
    },
    props:{

    },
    components:{
        AppGroup,
        FontAwesomeIcon
    },
    computed:{

    },
    data(){
        return {
            toggled:false,
            faTh,
            faPencilAlt,
            appGroups:[],
            SearchAll:''
        };
    },
    mounted(){
        document.addEventListener('click', this.close);
    },
    beforeDestroy(){
        document.removeEventListener('click', this.close);
    },
    created(){
        siteUserApi.getMyTiles().then((response) => {
            this.appGroups = response;
            this.initialized = true;
        });
    },
    methods:{
        close(e){
            if(this.toggled && !this.$el.contains(e.target)){
                this.toggled = false;
                this.SearchAll = '';
                this.runSearch();
                document.getElementById('Apps-Nav-List').scrollTop = 0;
            }
        },
        toggleAppsNav(){
            document.getElementById('Apps-Nav-List').scrollTop = 0;
            this.toggled = !this.toggled;
        },
        runSearch(){
            for(let i=0;i<this.$refs.AppGroup.length;i++){
                this.$refs.AppGroup[i].reProp(this.SearchAll);
            }
        }
    }
};
</script>