import siteUserApi from '@/api/boilerplate/siteUser.js';

const state = {
    ID:'',
    CSDNameID:'',
    IsStudent:false,
    IsGuardian:false,
    FirstName:'',
    LastName:'',
    Email:'',
    Username:'',
    CactusID:'',
    EmployeeID:'',
    BuildingLocCode:'',
    TotalLogin:'',
    LastIP:'',
    LastLogin:'',
    UserRole:'',
    StoredID:'',
    StoredIDType:'',
    Impersonated:'',
    UserPermGroups:[],
    ADPermGroups:[],
    Permissions:[],
    Schools:[],
    Students:[],
    initialized:false,

    StudentNumber:'',
    CanBeAssignedTicket:'',
    TeacherID:''
}

const getters = {

}

const actions = {
    getMe({ commit }) {
        return siteUserApi.getMe().then(response => {
            commit('setMe', response);
        })
    }
}

const mutations = {
    setMe(state, payload) {
        state.ID = payload.ID
        state.CSDNameID = payload.CSDNameID
        state.IsStudent = payload.IsStudent
        state.IsGuardian = payload.IsGuardian
        state.FirstName = payload.FirstName
        state.LastName = payload.LastName
        state.Email = payload.Email
        state.Username = payload.Username
        state.CactusID = payload.CactusID
        state.EmployeeID = payload.EmployeeID
        state.BuildingLocCode = payload.BuildingLocCode
        state.TotalLogin = payload.TotalLogin
        state.LastIP = payload.LastIP
        state.LastLogin = payload.LastLogin
        state.UserRole = payload.UserRole
        state.StoredID = payload.StoredID
        state.StoredIDType = payload.StoredIDType
        state.Impersonated = payload.Impersonated
        state.UserPermGroups = payload.UserPermGroups
        state.ADPermGroups = payload.ADPermGroups
        state.Permissions = payload.Permissions
        state.Schools = payload.Schools
        state.Students = payload.Students

        state.StudentNumber = payload.StudentNumber
        state.CanBeAssignedTicket = payload.CanBeAssignedTicket
        state.TeacherID = payload.TeacherID
        
        state.initialized = true
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}