import cloneDeep from 'lodash.clonedeep';
import clone from 'lodash.clone';

const state = {
    lastModal: {},
    visible: false,
    successPayload: {},
    success: false,
    data: {},
    name: '',
    lastState: {}
}

const getters = {
    isOpen: (state) => () => {
        return state.visible;
    }
}

const actions = {
    modalOpen({ commit }, payload) {
        commit('modalOpen', payload);
    },
    modalOpenLast({ commit }) {
        commit('modalOpenLast');
    },
    modalClose({ commit }, payload) {
        commit('modalClose', payload);
    },
    modalReset({ commit }) {
        commit('modalReset');
    }
}

const mutations = {
    modalOpen(state, payload) {
        state.visible = true;
        state.lastState.lastState = clone(state.lastState);
        state.lastState.data = cloneDeep(state.data);
        state.lastState.name = clone(state.name);
        state.lastState.success = cloneDeep(state.success);
        state.lastState.successPayload = clone(state.successPayload);
        state.success = false;
        state.successPayload = {};
        if((payload || {}).data)
            state.data = payload.data;
        else
            state.data = {};
        
        if((payload || {}).name) 
            state.name = payload.name;
        else
            state.name = '';
    },
    modalOpenLast(state) {
        state.name = clone(state.lastState.name);
        state.success = clone(state.lastState.success);
        state.successPayload = clone(state.lastState.successPayload);
        state.data = cloneDeep(state.lastState.data);
        state.lastState = cloneDeep(state.lastState.lastState);
    },
    modalClose(state, payload) {
        state.visible = false;
        state.data = {};

        if(payload !== true && typeof payload === "object") {
            state.successPayload = payload;
            state.success = true;
        }
        else if(payload === true) {
            state.success = true;
        }

        state.lastState = {};
    },
    modalReset(state) {
        state.visible = false;
        state.lastModal = {};
        state.data = {};
        state.name = '';
        state.lastState = {};
        state.success = false;
        state.successPayload = {};
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}